* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

.home,
.teknikguide,
.quiz,
.login {
  display: flex;
  height: 30vh;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
}

.teknikguide {
  background-image: url('./public/images/teknik-2.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 70px;
}

.quiz {
  background-image: url('./public/images/questions.jpg');
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 70px;
}

.login {
  background-image: url('./public/images/IMGP3985.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 70px;
}